
import TwoCol from "@/components/storyblok/containers/TwoCol"

export default {
    name: "TwoColOverlayedBlok",
    components: {
        TwoCol,
    },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
}
