
export default {
    name: "TwoColBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        reverseOrder() {
            if (!this.blok.mobileReverse) return
            return "last"
        },
        style() {
            const style = {}
            /** To be fixed using grid + real gap */
            if (this.blok.gap) {
                style.padding = `${this.blok.gap}px`
            }
            return style
        },
        leftClasses() {
            return [
                this.blok.leftAlignment,
                {
                    "d-none d-md-flex": this.blok.hideLeftOnMobile,
                    "d-flex": !this.blok.hideLeftOnMobile,
                },
            ]
        },
        rightClasses() {
            return [
                this.blok.rightAlignment,
                {
                    "d-none d-md-flex": this.blok.hideRightOnMobile,
                    "d-flex": !this.blok.hideRightOnMobile,
                },
            ]
        },
    },
}
